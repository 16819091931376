import * as React from 'react'
import { SP, PC } from '../components/utils/media-query'
import Header from '../components/organisms/Header'
import Footer from '../components/organisms/Footer'
import HeadingIllust from '../components/atoms/HeadingIlust'
const styles = require('../assets/scss/modules/companyProfile.module.scss')

const title = '企業概要'
const pageName = 'Company Profile'

const CompanyProfile = () => {
  const spImage = require('images/sp/company_profile_0.png')
  const pcImage = require('images/pc/company_profile_0.png')
  return (
    <section className={styles.companyProfile}>
      <Header />
      <div>
        <SP>
          <HeadingIllust
            image={spImage}
            title={title}
            pageName={pageName}
            top={15}
          />
        </SP>
        <PC>
          <HeadingIllust
            image={pcImage}
            title={title}
            pageName={pageName}
            top={25}
          />
        </PC>
      </div>
      <div className={styles.contentsWrapper}>
        <div className={styles.contents}>
          <ul>
            <li className={styles.firstChild}>
              <h5>会社名</h5>
              <p>ニチコマ合同会社</p>
            </li>
            <li>
              <h5>代表者</h5>
              <p>渡辺 健太</p>
            </li>
            <li>
              <h5>所在地</h5>
              <SP>
                <p>{`〒203-0012\n東京都東久留米市浅間町\n3丁目5番11号`}</p>
              </SP>
              <PC>
                <p>{`〒203-0012 東京都東久留米市浅間町3丁目5番11号`}</p>
              </PC>
            </li>
            <li>
              <h5>連絡先</h5>
              <div>
                <p>info@nichicoma.co.jp</p>
              </div>
            </li>
            <li>
              <h5>事業内容</h5>
              <SP>
                <div>
                  <p className="mt-4">ウェブシステム</p>
                  <p className="mb-4">アプリ開発</p>
                </div>
              </SP>
              <PC>
                <p>ウェブシステム／アプリ開発</p>
              </PC>
            </li>
          </ul>
        </div>
      </div>
      <SP>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202.3576138917828!2d139.5442504923134!3d35.757641497584395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f130!3m3!1m2!1s0x6018e8beb9e16c97%3A0x6d765b7b0acb7efb!2z44OL44OB44Kz44Oe5ZCI5ZCM5Lya56S-!5e0!3m2!1sja!2sjp!4v1601367261654!5m2!1sja!2sjp"
          width="90%"
          height={350}
          frameBorder={0}
          allowFullScreen={false}
          aria-hidden={false}
          tabIndex={0}
        />
      </SP>
      <PC>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202.3576138917828!2d139.5442504923134!3d35.757641497584395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f130!3m3!1m2!1s0x6018e8beb9e16c97%3A0x6d765b7b0acb7efb!2z44OL44OB44Kz44Oe5ZCI5ZCM5Lya56S-!5e0!3m2!1sja!2sjp!4v1601367261654!5m2!1sja!2sjp"
          width="100%"
          height={350}
          frameBorder={0}
          allowFullScreen={false}
          aria-hidden={false}
          tabIndex={0}
        />
      </PC>
      <Footer />
    </section>
  )
}
export default CompanyProfile
